<template lang="pug">
.field.date-field(:class="{ error }")
  label(v-if="!hideLabel", :for="name", :class="{ 'required': required }")
    | {{ labelText }}

  date-picker(
    ref="datePicker"
    input-class-name="date-picker-input"
    :model-value="modelValue"
    :range="range"
    :enable-time-picker="enableTimePicker"
    locale="pt-BR"
    format="dd/MM/yyyy"
    :month-change-on-scroll="false"
    :day-names="dayNames"
    :start-date="startDate"
    :teleport="true"
    :name="name"
    :auto-apply="true"
    :required="required"
    :disabled="disabled"
    :min-date="minDate"
    :disabled-dates="disableAfterDay"
    @update:model-value="(val) => { $emit('update:modelValue', val) }"
  )
    template(#action-buttons)
      button.button-primary(@click="selectDate") {{ $t('.buttons.select') }}

    template(#input-icon)
      i.fa.fa-calendar-o

    template(#clear-icon="{ clear }")
      i.fa.fa-times(@click="clear")

  input-error(:message="error")
</template>
<script>
import InputField from "./input-field.vue"

export default {
  extends: InputField,

  props: {
    modelValue: { type: [Array, Date] },
    hideLabel: { type: Boolean, default: false },
    enableTimePicker: { type: Boolean, default: false },
    startDate: { type: Date, default: null },
    range: { type: Boolean, default: false },
    name: { type: String, default: 'date' },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    minDate: { type: Date, default: null },
    disabledDay: { type: String, default: '-' },
  },

  mounted() {
    const element = this.$refs.datePicker.$el
    const container = element.getElementsByTagName('span')[0]

    container.addEventListener('click', () => {
      element.getElementsByTagName('input')[0].click()
    })
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "components.datePicker",
    }
  },

  methods: {
    selectDate() {
      this.$refs.datePicker.selectDate();
    },

    disableAfterDay(date) {
      return date.getDate() > this.disabledDay
    }
  },

  computed: {
    dayNames() {
      return this.$t('date.abbr_day_names')
    }
  }
}
</script>
<style lang="scss">
.date-field {
  margin-bottom: 1.5rem;
}

label.required::after {
  content: ' *';
  color: $primary-color
}

.custom-select {
  background-color: $primary-color;
  color: $white-color;
  border: none;
}

.date-field.error {
  & > label {
    color: red;
  }

  input {
    border-color: red;
  }
}

.date-picker-input {
  height: 38px;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 14px;
  color: $second-color-light;
}

input.dp__pointer {
  margin-bottom: 0;
}

.dp__clear_icon, .dp__input_icon {
  position: absolute;
  top: 14%;
  right: 0;
  transform: unset;
}

.dp__input_icon {
  padding-left: 1rem;
}

.dp__clear_icon {
  padding-right: 1rem;
}

.dp__menu {
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.1);

  &:focus {
    border: none;
  }
}

.dp__theme_light {
  --dp-primary-color: #9B53D7;
}

:root {
  --dp-font-family: "Lato";
  --dp-font-size: 1.2rem;
  --dp-preview-font-size: 1.2rem;
  --dp-row-maring: 10px 0px 10px 12px;
}

@media only screen and (max-width: 768px) {
  .dp__menu {
    max-width: 28rem;
    width: 95%;
    font-size: 1.5rem;

    &:focus {
      border: none;
    }
  }
}
</style>